<template>
  <section>
    <vueper-slides
      ref="vueperslides1"
      :touchable="false"
      fade
      :autoplay="false"
      :bullets="false"
      @slide="
        $refs.vueperslides2.goToSlide($event.currentSlide.index, {
          emit: false,
        })
      "
      fixed-height="400px"
    >
      <vueper-slide v-for="(slide, i) in slides" :key="i" :image="slide.image">
      </vueper-slide>
    </vueper-slides>

    <vueper-slides
      class="no-shadow thumbnails"
      ref="vueperslides2"
      @slide="
        $refs.vueperslides1.goToSlide($event.currentSlide.index, {
          emit: false,
        })
      "
      :visible-slides="slides.length"
      fixed-height="75px"
      :bullets="false"
      :touchable="false"
      :gap="2.5"
      :arrows="false"
    >
      <vueper-slide
        v-for="(slide, i) in slides"
        :key="i"
        :image="slide.image"
        :alt="slide.alt"
        @click.native="$refs.vueperslides2.goToSlide(i)"
      >
      </vueper-slide>
    </vueper-slides>
  </section>
</template>
<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
export default {
  components: { VueperSlides, VueperSlide },
  props: ["slides"],
};
</script>
